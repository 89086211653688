import { stringifyUrl } from 'query-string'

import stringifyOptions from './stringify-options'

type WhatsAppProps = {
  text: string
}

const shareToWhatsApp = (
  countryCode?: string,
  phoneNumber?: string,
  query?: Partial<WhatsAppProps>
) =>
  stringifyUrl(
    {
      url: `https://wa.me/${countryCode}${phoneNumber}`,
      query,
    },
    stringifyOptions
  )

export default shareToWhatsApp
