import classNames, { Argument } from 'classnames'
import { twMerge } from 'tailwind-merge'

export default function cn(...inputs: Array<Argument>) {
  return twMerge(classNames(inputs))
}

export function getGoogleMapLocationUrl({
  lat,
  long,
  placeId,
}: {
  lat: number
  long: number
  placeId: string
}) {
  return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}&query_place_id=${placeId}`
}

export function getGoogleStaticImageUrl({
  center,
  size = {
    width: 600,
    height: 300,
  },
}: {
  center: {
    lat: number
    long: number
  }
  size?: {
    width: number
    height: number
  }
}) {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat},${center.long}&zoom=13&size=${size.width}x${size.height}&maptype=roadmap
	&markers=color:red%7C%7C${center.lat},${center.long}
	&key=${process.env.NEXT_PUBLIC_GOOGLE_STATIC_MAP_API_KEY}`
}
