import { StringifyOptions } from 'query-string'

const stringifyOptions: StringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
  arrayFormat: 'bracket',
  encode: true,
}

export default stringifyOptions
