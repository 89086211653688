import cn from '@helpers/utils'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import SideNav from '../side-nav'

type Props = {
  children?: ReactNode
  scrollLayout?: boolean
  mainClassname?: string
  wrapperClassName?: string
}

const Layout = ({
  children,
  scrollLayout = true,
  mainClassname,
  wrapperClassName,
}: Props) => (
  <div className={cn('flex h-screen', wrapperClassName)}>
    <div className="w-[82px] shrink-0">
      <SideNav />
    </div>
    <main
      className={classNames(
        'w-[calc(100vw-82px)]',
        scrollLayout &&
          'overflow-y-scroll scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-blue-500 hover:scrollbar-thumb-blue-700',
        mainClassname
      )}
    >
      {children}
    </main>
  </div>
)

export default Layout
