import classNames from 'classnames'
import React from 'react'

import CaretIcon from '@common/icons/CaretIcon'

import { PaginationProps } from './types'

const Pagination = ({
  page,
  onChangePage,
  totalPage,
  className = '',
  ...restProps
}: PaginationProps) => {
  const onClickPage = (n: number) => () => {
    onChangePage(n)
  }

  const renderButtons = () => {
    const result: React.ReactNode[] = []
    if (totalPage > 7) {
      result.push(
        <button
          key="btn-page-1"
          className={classNames(
            'rounded-md',
            'border',
            'p-0',
            'w-[32px]',
            'h-[32px]',
            page === 1
              ? 'border-primary-blue text-primary-blue'
              : 'border-border-gray text-primary-gray'
          )}
          type="button"
          onClick={onClickPage(1)}
        >
          1
        </button>
      )

      if (page < 4) {
        for (let i = 2; i <= 5; i += 1) {
          result.push(
            <button
              key={`btn-page-${i}`}
              className={classNames(
                'rounded-md',
                'border',
                'p-0',
                'w-[32px]',
                'h-[32px]',
                i === page
                  ? 'border-primary-blue text-primary-blue'
                  : 'border-border-gray text-primary-gray'
              )}
              type="button"
              onClick={onClickPage(i)}
            >
              {i}
            </button>
          )
        }
        result.push(
          <div className="h-[32px] w-[32px] text-center" key="left-dots">
            ...
          </div>
        )
      } else if (page >= 4 && page <= totalPage - 3) {
        result.push(
          <div className="h-[32px] w-[32px] text-center" key="left-dots">
            ...
          </div>
        )
        for (let i = page - 1; i <= page + 1; i += 1) {
          result.push(
            <button
              key={`btn-page-${i}`}
              className={classNames(
                'rounded-md',
                'border',
                'p-0',
                'w-[32px]',
                'h-[32px]',
                i === page
                  ? 'border-primary-blue text-primary-blue'
                  : 'border-border-gray text-primary-gray'
              )}
              type="button"
              onClick={onClickPage(i)}
            >
              {i}
            </button>
          )
        }
        result.push(
          <div className="h-[32px] w-[32px] text-center" key="right-dots">
            ...
          </div>
        )
      } else {
        result.push(
          <div className="h-[32px] w-[32px] text-center" key="right-dots">
            ...
          </div>
        )
        for (let i = totalPage - 4; i < totalPage; i += 1) {
          result.push(
            <button
              key={`btn-page-${i}`}
              className={classNames(
                'rounded-md',
                'border',
                'p-0',
                'w-[32px]',
                'h-[32px]',
                i === page
                  ? 'border-primary-blue text-primary-blue'
                  : 'border-border-gray text-primary-gray'
              )}
              type="button"
              onClick={onClickPage(i)}
            >
              {i}
            </button>
          )
        }
      }
      result.push(
        <button
          key={`btn-page-${totalPage}`}
          className={classNames(
            'rounded-md',
            'border',
            'p-0',
            'w-[32px]',
            'h-[32px]',
            totalPage === page
              ? 'border-primary-blue text-primary-blue'
              : 'border-border-gray text-primary-gray'
          )}
          type="button"
          onClick={onClickPage(totalPage)}
        >
          {totalPage}
        </button>
      )
    } else {
      for (let i = 1; i <= totalPage; i += 1) {
        result.push(
          <button
            key={`btn-page-${i}`}
            className={classNames(
              'rounded-md',
              'border',
              'p-0',
              'w-[32px]',
              'h-[32px]',
              i === page
                ? 'border-primary-blue text-primary-blue'
                : 'border-border-gray text-primary-gray'
            )}
            type="button"
            onClick={onClickPage(i)}
          >
            {i}
          </button>
        )
      }
    }

    return result
  }

  return (
    <div
      className={classNames(
        'flex flex-wrap gap-x-[8px] gap-y-[2px]',
        className
      )}
      {...restProps}
    >
      <button
        key="btn-page-prev"
        className={classNames(
          'text-primary-gray',
          'rounded-md',
          'border',
          'border-border-gray',
          'p-0',
          'w-[32px]',
          'h-[32px]',
          page <= 1 ? 'bg-board-gray' : ''
        )}
        type="button"
        disabled={page <= 1}
        onClick={onClickPage(Math.max(1, page - 1))}
      >
        <CaretIcon className="mx-auto" orientation="left" />
      </button>
      {renderButtons()}
      <button
        key="btn-page-next"
        className={classNames(
          'text-primary-gray',
          'rounded-md',
          'border',
          'border-border-gray',
          'p-0',
          'w-[32px]',
          'h-[32px]',
          page >= totalPage ? 'bg-board-gray' : ''
        )}
        type="button"
        disabled={page >= totalPage}
        onClick={onClickPage(Math.min(page + 1, totalPage))}
      >
        <CaretIcon className="mx-auto" orientation="right" />
      </button>
    </div>
  )
}

export default Pagination
