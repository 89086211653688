import React, { ReactNode } from 'react'

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode
  weight?: 'medium' | 'normal' | 'bold'
  accent?: 'primary' | 'secondary'
  className?: string
}

const H2 = ({
  children,
  weight = 'medium',
  accent = 'primary',
  className: classNameProp,
}: Props) => {
  let className = `text-2xl font-${weight}`

  if (accent === 'primary') {
    className += ' text-primary-black'
  } else if (accent === 'secondary') {
    className += ' text-secondary-gray'
  }

  if (classNameProp) {
    className += ` ${classNameProp}`
  }

  return <h2 className={className}>{children}</h2>
}

export default H2
