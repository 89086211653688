/* eslint-disable react/prop-types */

/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import React from 'react'

type CaretIconProps = React.SVGProps<SVGSVGElement> & {
  orientation?: 'down' | 'up' | 'left' | 'right'
}

const rotations = {
  down: 'rotate-0',
  up: 'rotate-180',
  left: 'rotate-90',
  right: 'rotate-[-90deg]',
}

const CaretIcon = ({
  orientation = 'down',
  className,
  ...restProps
}: CaretIconProps) => {
  let classes = rotations[orientation]
  classes = classNames(classes, className)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={classes}
      {...restProps}
    >
      <path
        d="M13.7806 7.71357C14.0596 7.42881 14.5118 7.42881 14.7908 7.71357C15.0697 7.99833 15.0697 8.46001 14.7908 8.74476L10.5051 13.1198C10.2261 13.4045 9.77387 13.4045 9.49492 13.1198L5.20921 8.74476C4.93026 8.46001 4.93026 7.99832 5.20921 7.71357C5.48816 7.42881 5.94042 7.42881 6.21936 7.71357L10 11.573L13.7806 7.71357Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CaretIcon
