import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  weight?: 'normal' | 'medium' | 'bold'
  italic?: boolean
  accent?: 'primary' | 'secondary'
}

const Subtitle = ({
  children,
  weight = 'normal',
  italic,
  accent = 'secondary',
}: Props) => (
  <span
    className={classNames(
      `text-sm font-${weight}`,
      italic && 'italic',
      // eslint-disable-next-line no-nested-ternary
      accent === 'primary'
        ? 'text-primary-black'
        : accent === 'secondary'
          ? 'text-primary-gray'
          : ''
    )}
  >
    {children}
  </span>
)

export default Subtitle
