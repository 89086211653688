/* eslint-disable react/destructuring-assignment */
import debounce from 'debounce-promise'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { OptionProps, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import Pagination from '@features/applications/components/pagination/Pagination'
import useCoinsBalance from '@features/coins/hooks/useCoinsBalance'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'
import { switchCompany } from '@features/login/services/LoginServices'
import { getProfile, profileActions } from '@features/profile/reducers'

import Button from '@components/button'
import Subtitle from '@components/typography/subtitle'

import { useAppDispatch, useAppSelector } from '@hooks/redux'
import usePjpCompanies from '@hooks/use-pjp-companies'

import {
  ATMA_EMPLOYER_ACCESS_TOKEN,
  ATMA_EMPLOYER_REFRESH_TOKEN,
} from '@constants/storage-items'
import { EMPLOYERS_PROFILE_URL, INITIALIZE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'

import { CompanyProps, PjpCompanyProps } from '@custom-types/index'

import Modal from '../modal'

const CustomCompanyFieldOption = (
  props: OptionProps<PjpCompanyProps, false>
) => (
  <components.Option {...props}>
    <div className="flex items-center">
      <div className="relative h-10 w-10 overflow-hidden rounded-full">
        <Image
          layout="fill"
          alt="logo"
          src={props.data.logoURL || 'https://via.placeholder.com/36x36'}
        />
      </div>
      <div className="ml-[12px]">
        <div>{props.data?.companyName}</div>
        <div>{props.data?.industry.industryName}</div>
      </div>
    </div>
  </components.Option>
)

type CompanyListProps = {
  companyData: PjpCompanyProps[]
  company: CompanyProps
  selectedCompany: string
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>
  setCompanyName: React.Dispatch<React.SetStateAction<string>>
}

const CompanyList = ({
  companyData,
  selectedCompany,
  setSelectedCompany,
  company,
  setCompanyName,
}: CompanyListProps) => (
  <div className="mt-4 grid grid-cols-2 gap-4">
    {companyData?.map((data) => (
      <button
        key={data.id}
        className={`flex items-center justify-between rounded-lg border px-4 py-2 ${
          selectedCompany === data.id ? 'border-[#4460AC]' : ''
        }`}
        onClick={() => {
          setSelectedCompany(data.id)
          setCompanyName(data.companyName)
        }}
        type="button"
        tabIndex={0}
      >
        <div className="flex items-center">
          <div className="relative mr-2 h-10 w-10 overflow-hidden rounded-full">
            <Image
              alt="ic_job"
              src={
                data.logoURL
                  ? data.logoURL
                  : '/assets/icons/ic_job_account_inactive.svg'
              }
              layout="fill"
            />
          </div>
          <div className="text-start">
            <p>{data.companyName}</p>
            <Subtitle>
              {data.id === company?.id
                ? 'Sedang dilihat'
                : `${data.activeJobsCount} loker aktif`}
            </Subtitle>
          </div>
        </div>
        <input
          type="radio"
          className="h-5 w-5 border border-gray-500 text-primary-blue outline-none hover:cursor-pointer"
          disabled
          checked={
            selectedCompany !== ''
              ? data.id === selectedCompany
              : data.id === company?.id
          }
        />
      </button>
    ))}
  </div>
)

type Props = {
  open: boolean
  onClose: () => void
}

const SwitchCompanyModal = ({ open, onClose }: Props) => {
  const route = useRouter()
  const dispatch = useAppDispatch()
  const { company } = useAppSelector((state) => state.profileReducer)
  const [selectedCompany, setSelectedCompany] = useState<string>(
    company?.id as string
  )
  const [searchText, setSearchText] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [companyName, setCompanyName] = useState<string>('')

  const { pjpCompaniesData } = usePjpCompanies(searchText, page, company?.id)

  const { mutate: mutateEmployerProfile } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  const { mutate: mutateSwrConfig } = useSWRConfig()

  const { mutateCoinsBalance } = useCoinsBalance()

  const onChangeCompany = async () => {
    try {
      const res = await switchCompany(selectedCompany as string)

      localStorage.setItem(ATMA_EMPLOYER_ACCESS_TOKEN, res.accessToken)
      localStorage.setItem(ATMA_EMPLOYER_REFRESH_TOKEN, res.refreshToken)

      const userData = await dispatch(getProfile()).unwrap()
      await mutateSwrConfig(INITIALIZE_URL)
      mutateEmployerProfile()
      mutateCoinsBalance()
      dispatch(profileActions.saveNewProfile(userData))
      onClose()
      await route.replace('/jobs')
      toast.success(
        `Berhasil switch perusahaan. Sekarang Anda melihat ${companyName}`,
        {
          className: 'bg-[#52B788] text-white',
          icon: false,
        }
      )
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setPage(1)
        setSearchText('')
        onClose()
      }}
      title="Switch ke perusahaan lain"
      classNamePanel="w-[45rem] h-[38rem]"
    >
      <div>
        <AsyncSelect
          placeholder="Cari perusahaan lain"
          defaultOptions
          isClearable
          getOptionLabel={(option: PjpCompanyProps) => option.companyName}
          getOptionValue={(option: PjpCompanyProps) => option.id}
          styles={{
            control: (base, props) => ({
              ...base,
              paddingTop: '0.25rem',
              paddingBottom: '0.25rem',
              backgroundColor: props.isDisabled
                ? 'rgb(229 231 235)'
                : base.backgroundColor,
            }),
          }}
          loadOptions={debounce(() => pjpCompaniesData?.records, 1500)}
          isSearchable
          onInputChange={(val, x) => {
            if (x.action === 'input-change') {
              setPage(1)
              setSearchText(val)
            }
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: CustomCompanyFieldOption,
            Menu: () => null,
          }}
          inputValue={searchText}
        />
        <CompanyList
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          company={company as CompanyProps}
          companyData={pjpCompaniesData?.records}
          setCompanyName={setCompanyName}
        />
        <Pagination
          className="mt-4 justify-center"
          page={pjpCompaniesData?.metaData?.page}
          totalPage={pjpCompaniesData?.metaData?.totalCount}
          onChangePage={(pageNum) => setPage(pageNum)}
        />
        <div className="mt-4 flex justify-between">
          <Button
            appearance="outline-primary"
            className="mr-2 flex-1"
            type="button"
            onClick={() => {
              setSearchText('')
              setPage(1)
              onClose()
            }}
          >
            Batal
          </Button>
          <Button
            appearance="primary"
            className="flex-1"
            type="button"
            onClick={onChangeCompany}
            disabled={company?.id === selectedCompany}
          >
            Terapkan
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SwitchCompanyModal
