/* eslint-disable no-use-before-define */
import amplitude from 'amplitude-js'
import React from 'react'
import useSWR from 'swr'

import useCoinsFeatureToggle from '@hooks/use-coins-feature-toggle'

import { COINS_BALANCE_URL } from '@constants/url'

import { GetCoinsBalanceAPIResponse } from '../types'

export const useCoinsBalance = () => {
  const { isCoinsFeatureEnabled } = useCoinsFeatureToggle()

  const { data, mutate, isLoading, isValidating } =
    useSWR<GetCoinsBalanceAPIResponse>(
      isCoinsFeatureEnabled ? COINS_BALANCE_URL : null
    )

  React.useEffect(() => {
    if (!data) return

    amplitude.getInstance().setUserProperties({
      atma_coins_balance: data.balance,
    })
  }, [data])

  return {
    coinsBalanceData: data,
    mutateCoinsBalance: mutate,
    coinsBalanceIsLoading: isLoading,
    isValidating,
  }
}

export default useCoinsBalance
