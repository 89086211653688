import { post } from '@helpers/fetch-wrapper'
import useSWRImmutable from 'swr/immutable'

import { INITIALIZE_URL } from '@constants/url'

export default function useCoinsFeatureToggle() {
  const initializeQueryResult = useSWRImmutable<{
    featureToggle: {
      canBoost: boolean
      canUseCoins: boolean
    }
  }>(INITIALIZE_URL, async (url: string) => {
    const response = await post(url)
    return response.data
  })

  const isCoinsFeatureEnabled =
    initializeQueryResult.data?.featureToggle.canUseCoins || false

  return {
    ...initializeQueryResult,
    isCoinsFeatureEnabled,
  }
}
