import axios from 'axios'

export const errorFormat = (error: unknown): string => {
  let formattedError: string

  if (axios.isAxiosError(error)) {
    formattedError = `Error (${error?.response?.status}): `

    if (error?.response?.data) {
      if (Array.isArray(error?.response?.data?.message)) {
        formattedError += error?.response?.data?.message?.join(',')
      } else {
        formattedError += error?.response?.data?.message
      }
    } else if (error.request) {
      formattedError += error?.request
    } else {
      formattedError += error?.message
    }
  } else {
    formattedError = (error as Error)?.message
  }

  return formattedError
}

export default errorFormat
